import { FC } from "react";
import { Modal, ModalProps, Box } from "@mui/material";

interface ILoader {
  open: boolean;
}

const Loader: FC<ILoader> = ({ open }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{width: '100%', height: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <svg
          version="1.1"
          width={60}
          height={60}
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
        >
          <path
            fill="#fff"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </Box>
    </Modal>
  );
};

export default Loader;
