import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICartItem } from "../types/cart-item";

interface ICartState {
  cart: ICartItem[];
}

const initialState: ICartState = {
  cart: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCartItem: (state, action: PayloadAction<ICartItem>) => {
      const index = state.cart.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item.size === action.payload.size &&
          item.color === action.payload.color
      );
      if (index !== -1) {
        state.cart[index].quantity += action.payload.quantity;
      } else {
        state.cart.push(action.payload);
      }
    },
    removeCartItem: (state, action: PayloadAction<number>) => {
      state.cart.splice(action.payload, 1);
    },
    changeQuantity: (
      state,
      action: PayloadAction<{ index: number; quantity: number }>
    ) => {
      state.cart[action.payload.index].quantity = action.payload.quantity;
    },
  },
});

export const { addCartItem, removeCartItem, changeQuantity } =
  cartSlice.actions;
