import { FC } from "react";
import { Container, Typography, Box, Link, useTheme } from "@mui/material";
import campImage from "../../assets/camps-page/camp-1.jpg";
import { Helmet } from "react-helmet";

const CampsPage: FC = () => {
  const theme = useTheme();

  return (
    <section>
      <Helmet>
        <title>Учебно-тренировочные сборы</title>
        <meta name="description" content="Предстартовые сезонные, так и ежегодные сборы для повышения физической и технической подготовки спортсменов." />
        <meta name="keywords" content="спортивные сборы, лагерь, повышение физической подготовки" />
      </Helmet>
      <Container>
        <Typography variant="h1" textAlign="center" mb={4} fontSize={24}>
          Учебно-тренировочные сборы
        </Typography>
        <Typography textAlign="center" mb={4}>
          Предстартовые сезонные,так и ежегодные сборы для повышения физической
          и технической подготовки спортсменов.
        </Typography>
        <Box
          component="img"
          sx={{ width: "100%" }}
          src={campImage}
          alt="Фото со сборов"
        ></Box>
        <Typography mt={4}>
          Ежегодный Муай тай лагерь пройдет с 17-30 июня 2024 г. Анапа
        </Typography>
        <Typography mt={2}>
          Подробности по тел:{" "}
          <Link
            sx={{
              color: theme.palette.primary.contrastText,
              textDecoration: "none",
            }}
            href="tel:+79167375174"
          >
            +7 916 737-51-74
          </Link>
        </Typography>
      </Container>
    </section>
  );
};

export default CampsPage;
