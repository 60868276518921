import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../types";
import {
  deleteGalleryImageApi,
  deleteGalleryVideoApi,
  getGalleryImagesApi,
  getGalleryVideosApi,
  postGalleryImageApi,
  postGalleryVideoApi,
} from "../api";

interface IGalleryState {
  files: { id: number; type: "image" | "video" }[];
  getImagesRequest: boolean;
  getImagesFailed: boolean;
  postImageRequest: boolean;
  postImageFailed: boolean;
  deleteImageRequest: boolean;
  deleteImageFailed: boolean;
  getVideosRequest: boolean;
  getVideosFailed: boolean;
  postVideoRequest: boolean;
  postVideoFailed: boolean;
  deleteVideoRequest: boolean;
  deleteVideoFailed: boolean;
}

const initialState: IGalleryState = {
  files: [],
  getImagesRequest: false,
  getImagesFailed: false,
  postImageRequest: false,
  postImageFailed: false,
  deleteImageRequest: false,
  deleteImageFailed: false,
  getVideosRequest: false,
  getVideosFailed: false,
  postVideoRequest: false,
  postVideoFailed: false,
  deleteVideoRequest: false,
  deleteVideoFailed: false,
};

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    getImagesRequest: (state) => {
      state.getImagesRequest = true;
    },
    getImagesSuccess: (state, action: PayloadAction<number[]>) => {
      state.getImagesRequest = false;
      state.getImagesFailed = false;
      const videos = state.files.filter(({ type }) => type === "video");
      state.files = [
        ...videos,
        ...action.payload.map((id) => ({ id, type: "image" as "image" })),
      ];
    },
    getImagesFailed: (state) => {
      state.getImagesRequest = false;
      state.getImagesFailed = true;
    },
    postImageRequest: (state) => {
      state.postImageRequest = true;
    },
    postImageSuccess: (state, action: PayloadAction<number>) => {
      state.postImageRequest = false;
      state.postImageFailed = false;
      state.files.push({ id: action.payload, type: "image" });
    },
    postImageFailed: (state) => {
      state.postImageRequest = false;
      state.postImageFailed = true;
    },
    deleteImageRequest: (state) => {
      state.deleteImageRequest = true;
    },
    deleteImageSuccess: (state, action: PayloadAction<number>) => {
      state.deleteImageRequest = false;
      state.deleteImageFailed = false;
      const index = state.files.findIndex((item) => item.id === action.payload);
      state.files.splice(index, 1);
    },
    deleteImageFailed: (state) => {
      state.deleteImageRequest = false;
      state.deleteImageFailed = true;
    },
    getVideosRequest: (state) => {
      state.getVideosRequest = false;
    },
    getVideosSuccess: (state, action: PayloadAction<number[]>) => {
      state.getVideosRequest = false;
      state.getVideosFailed = false;
      const images = state.files.filter(({ type }) => type === "image");
      state.files = [
        ...action.payload.map((id) => ({ id, type: "video" as "video" })),
        ...images,
      ];
    },
    getVideosFailed: (state) => {
      state.getVideosRequest = false;
      state.getVideosFailed = true;
    },
    postVideoRequest: (state) => {
      state.postVideoRequest = true;
    },
    postVideoSuccess: (state, action: PayloadAction<number>) => {
      state.postVideoRequest = false;
      state.postVideoFailed = false;
      state.files.push({ id: action.payload, type: "video" });
    },
    postVideoFailed: (state) => {
      state.postVideoRequest = false;
      state.postVideoFailed = true;
    },
    deleteVideoRequest: (state) => {
      state.deleteVideoRequest = true;
    },
    deleteVideoSuccess: (state, action: PayloadAction<number>) => {
      state.deleteVideoRequest = false;
      state.deleteVideoFailed = false;
      const index = state.files.findIndex((item) => item.id === action.payload);
      state.files.splice(index, 1);
    },
    deleteVideoFailed: (state) => {
      state.deleteImageRequest = false;
      state.deleteVideoFailed = true;
    },
  },
});

const {
  getImagesRequest,
  getImagesSuccess,
  getImagesFailed,
  getVideosRequest,
  getVideosSuccess,
  getVideosFailed,
  postImageRequest,
  postImageSuccess,
  postImageFailed,
  postVideoRequest,
  postVideoSuccess,
  postVideoFailed,
  deleteImageRequest,
  deleteImageSuccess,
  deleteImageFailed,
  deleteVideoRequest,
  deleteVideoSuccess,
  deleteVideoFailed,
} = gallerySlice.actions;

export const getGalleryImages = (): AppThunk => (dispatch) => {
  dispatch(getImagesRequest());
  getGalleryImagesApi()
    .then((images) => dispatch(getImagesSuccess(images)))
    .catch(() => dispatch(getImagesFailed()));
};

export const getGalleryVideos = (): AppThunk => (dispatch) => {
  dispatch(getVideosRequest());
  getGalleryVideosApi()
    .then((videos) => dispatch(getVideosSuccess(videos)))
    .catch(() => dispatch(getVideosFailed()));
};

export const postGalleryImage =
  (formData: FormData): AppThunk =>
  (dispatch) => {
    dispatch(postImageRequest());
    postGalleryImageApi(formData)
      .then(({ id }) => dispatch(postImageSuccess(id)))
      .catch(() => dispatch(postImageFailed()));
  };

export const postGalleryVideo =
  (formData: FormData): AppThunk =>
  (dispatch) => {
    dispatch(postVideoRequest());
    postGalleryVideoApi(formData)
      .then(({ id }) => dispatch(postVideoSuccess(id)))
      .catch(() => dispatch(postVideoFailed()));
  };

export const deleteGalleryImage =
  (id: number): AppThunk =>
  (dispatch) => {
    dispatch(deleteImageRequest());
    deleteGalleryImageApi(id)
      .then(({ id }) => dispatch(deleteImageSuccess(id)))
      .catch(() => dispatch(deleteImageFailed()));
  };

export const deleteGalleryVideo =
  (id: number): AppThunk =>
  (dispatch) => {
    dispatch(deleteVideoRequest());
    deleteGalleryVideoApi(id)
      .then(({ id }) => dispatch(deleteVideoSuccess(id)))
      .catch(() => dispatch(deleteVideoFailed()));
  };
