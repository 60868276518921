import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

interface IPhoneMask {
  name: string;
}

const PhoneMask = forwardRef<HTMLElement, IPhoneMask>(function TextMaskCustom(
  props,
  ref
) {
  return (
    <IMaskInput
      {...props}
      mask="+7 (#00) 000 00-00"
      definitions={{
        "#": /[1-9]/,
      }}
      //@ts-ignore
      inputRef={ref}
      overwrite
    />
  );
});

export default PhoneMask;
