import { FC } from "react";
import { Container, Typography, Box } from "@mui/material";
import adultsImage from "../../assets/adults-page/adults.jpg";
import { Helmet } from "react-helmet";

const AdultsPage: FC = () => {
  return (
    <section>
      <Helmet>
        <title>Взрослые</title>
        <meta
          name="description"
          content="Современный подход и методики проверенные временем, дифференцированный подход к уровню и опыту занимающихся."
        />
        <meta
          name="keywords"
          content="тренировки для взрослых, современный подход, методики проверенные временем"
        />
      </Helmet>
      <Container>
        <Typography variant="h1" textAlign="center" mb={4} fontSize={24}>
          Взрослые
        </Typography>
        <Typography textAlign="center" mb={4}>
          Современный подход и методики проверенные временем, дифференцированный
          подход к уровню и опыту занимающихся.
        </Typography>
        <Box
          component="img"
          sx={{ maxWidth: "100%" }}
          src={adultsImage}
          alt="Тренировка"
        ></Box>
      </Container>
    </section>
  );
};

export default AdultsPage;
