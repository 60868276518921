import { ChangeEvent, FC, FormEvent, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { ICartItem } from "../../services/types/cart-item";
import {
  changeQuantity,
  removeCartItem,
} from "../../services/slices/cart-slice";
import { Routes } from "../../utils/constants";
import PhoneMask from "../../components/phone-mask/phone-mask";
import { IProduct } from "../../services/types/product";
import { postGoogleShopRequest } from "../../services/api";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";

const listGridStyles = {
  px: 0,
  py: 2,
  display: "grid",
  gridTemplateColumns: {
    xs: "2fr 1fr 1fr",
    lg: "1fr 2fr 1fr 1fr 1fr",
  },
  alignItems: "start",
  gap: { xs: 2, md: 3, lg: 4 },
};

const emptyListItemStyles = {
  display: {
    xs: "none",
    lg: "block",
  },
};

const CartItem: FC<ICartItem & { index: number }> = ({
  index,
  id,
  color,
  size,
  quantity,
  weight,
  price,
}) => {
  const product = useAppSelector(
    (store) =>
      store.products.products.find((item) => item.id === id) as IProduct
  );
  const [inputValue, setInputValue] = useState(String(quantity));
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matched = useMediaQuery(theme.breakpoints.up("lg"));

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.currentTarget.value;

    if (value === "") return setInputValue("");

    if (+value > 99) return setInputValue("99");

    if (+value < 0) return setInputValue("0");

    setInputValue(value);
  };

  const hanldeInputBlur = () => {
    if (inputValue === "0" || inputValue === "") setInputValue("1");

    dispatch(changeQuantity({ index, quantity: +inputValue }));
  };

  const handleButtonDelete = () => {
    dispatch(removeCartItem(index));
  };

  return (
    <ListItem
      sx={{
        ...listGridStyles,
        alignItems: "start",
      }}
    >
      <Box
        sx={{ width: "100%", display: { xs: "none", lg: "block" } }}
        component="img"
        src={product.images[color.key][0]}
        alt={product.name}
      />
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: { xs: 16, lg: 20 } }}>
          {product.name}
        </Typography>
        <Typography>
          цвет:{" "}
          {
            product.colors[
              product.colors.findIndex((item) => item.key === color.key)
            ].name
          }
        </Typography>
        <Typography>размер: {size}</Typography>
        {weight && <Typography>вес: {weight} кг</Typography>}
      </Box>
      <FormControl sx={{ width: 68 }}>
        <OutlinedInput
          sx={{ pr: 1 }}
          type="number"
          inputMode="decimal"
          inputProps={{ sx: { py: 2, pl: 2, pr: 0 }, min: 0, max: 99 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={hanldeInputBlur}
          endAdornment={
            <InputAdornment position="end">
              <Typography>шт.</Typography>
            </InputAdornment>
          }
        />
      </FormControl>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography>{quantity * price} руб.</Typography>
        {!matched ? (
          <IconButton onClick={handleButtonDelete}>
            <DeleteIcon />
          </IconButton>
        ) : null}
      </Box>
      {matched ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={handleButtonDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : null}
    </ListItem>
  );
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 4,
  bgcolor: "#111",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const CartPage = () => {
  const { cart } = useAppSelector((store) => store.cart);
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleModalOpen = (message: string) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsSending(false);
    setIsModalOpen(false);
    setModalMessage("");
  };

  const handleFormSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const form = evt.currentTarget;

    if (!form.checkValidity()) return form.reportValidity();

    setIsSending(true);

    const formData = new FormData(form);
    const products = JSON.stringify(cart);
    const slicedPhone = (formData.get("phone") as string).slice(1);

    formData.append("cart", products);
    formData.set("phone", slicedPhone);

    postGoogleShopRequest(formData)
      .then(() => {
        handleModalOpen("Заявка отправлена");
        form.reset();
      })
      .catch((err) => handleModalOpen(`Произошла ошибка ${err}`));
  };

  return (
    <Container>
      <Helmet>
        <title>Корзина</title>
        <meta
          name="description"
          content="Добавленные товары"
        />
        <meta
          name="keywords"
          content="покупки, корзина, товары, экипировка"
        />
      </Helmet>
      <Typography variant="h1" ml={4} mb={4} fontSize={24}>
        {!cart.length ? "Ваша корзина пуста" : "Ваши товары"}
      </Typography>
      {!cart.length ? (
        <Box sx={{ py: 20, display: "flex", justifyContent: "center" }}>
          <Box component={RouterLink} to={Routes.Shop}>
            <Button
              sx={{ width: "50vw", p: 3 }}
              color="secondary"
              variant="contained"
            >
              Перейти в магазин
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <List sx={listGridStyles}>
            <ListItem sx={emptyListItemStyles}></ListItem>
            {["Наименование", "Количество", "Цена"].map((string) => (
              <ListItem sx={{ padding: 0 }} key={string}>
                <Typography variant="h6" sx={{ fontSize: { xs: 16, lg: 20 } }}>
                  {string}
                </Typography>
              </ListItem>
            ))}
          </List>
          <List>
            {cart.map((cartItem, index) => (
              <CartItem
                key={cartItem.id + cartItem.color.key + cartItem.size}
                index={index}
                {...cartItem}
              />
            ))}
          </List>
          <List sx={{ ...listGridStyles, mb: 10 }}>
            <ListItem sx={emptyListItemStyles}></ListItem>
            <ListItem
              sx={{
                p: 0,
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h5">Итого:</Typography>
            </ListItem>
            <ListItem sx={{ p: 0, alignItems: "flex-start" }}>
              <Typography variant="h6">
                {cart.reduce((a, b) => a + b.quantity, 0)}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Box>
                <Typography variant="h6">
                  {cart.reduce((a, b) => a + b.quantity * b.price, 0)} руб.
                </Typography>
                <Typography>
                  {cart
                    .reduce(
                      (a, b) => a + b.quantity * (b.weight ? b.weight : 0),
                      0
                    )
                    .toFixed(2)}{" "}
                  кг
                </Typography>
              </Box>
            </ListItem>
          </List>
          <Box
            component="form"
            sx={{
              px: { xs: 4, md: 6, lg: 8, xl: 10 },
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
            onSubmit={handleFormSubmit}
          >
            <Typography variant="h4" textAlign="center">
              Отправить заявку
            </Typography>
            <TextField name="name" label="ФИО" required />
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="phone">Номер телефона *</InputLabel>
              <OutlinedInput
                id="phone"
                name="phone"
                type="tel"
                inputComponent={PhoneMask as any}
                label="Номер телефона"
                inputMode="tel"
                required
              />
            </FormControl>
            <TextField name="email" label="Почта" required />
            <Button type="submit" color="secondary" variant="contained">
              Отправить заявку
            </Button>
          </Box>
        </>
      )}
      <Loader open={isSending} />
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography textAlign="center">{modalMessage}</Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default CartPage;
