import { FC, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  Typography,
  styled,
} from "@mui/material";
import { ReactComponent as ArrowSvg } from "../../assets/shop-page/arrow.svg";
import { useNavigate } from "react-router-dom";
import { IProduct } from "../../services/types/product";
import { useAppSelector } from "../../hooks/redux-hooks";
import { Helmet } from "react-helmet";

const RightArrowSvg = styled(ArrowSvg)(() => ({
  width: 28,
  height: 28,
}));

const LeftArrowSvg = styled(RightArrowSvg)(() => ({
  transform: "rotate(180deg)",
}));

const Product: FC<IProduct> = ({ id, name, images, price }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const navigate = useNavigate();

  const handleLeftArrowClick = () => {
    const index = imageIndex - 1;

    if (index < 0) return setImageIndex(Object.values(images).length - 1);

    setImageIndex(index);
  };

  const handleRightButtonClick = () => {
    const index = imageIndex + 1;

    if (index >= Object.values(images).length) return setImageIndex(0);

    setImageIndex(index);
  };

  const hanldeProductClick = (evt: any) => {
    if (["BUTTON", "svg", "path"].includes(evt.target.tagName)) return;

    navigate(`/shop/${id}`);
  };

  return (
    <ListItem
      sx={{
        maxWidth: { xs: 280, sm: 300, md: 320, lg: 360, xl: 400 },
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={hanldeProductClick}
    >
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{ maxWidth: "100%" }}
          component="img"
          src={Object.values(images)[imageIndex][0]}
          alt={name}
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton sx={{ borderRadius: 0 }} onClick={handleLeftArrowClick}>
            <LeftArrowSvg />
          </IconButton>
          <IconButton sx={{ borderRadius: 0 }} onClick={handleRightButtonClick}>
            <RightArrowSvg />
          </IconButton>
        </Box>
      </Box>
      <Typography variant="h6" textAlign="center">
        {name}
      </Typography>
      <Typography variant="body1">{price} ₽</Typography>
    </ListItem>
  );
};

const ShopPage: FC = () => {
  const { products } = useAppSelector((store) => store.products);

  return (
    <section>
      <Helmet>
        <title>Магазин</title>
        <meta name="description" content="Экипировка для тренировок" />
        <meta name="keywords" content="экипировка, боксерские перчатки, шлем, перчатки боксерские, жилет защитный, защита голеностопа" />
      </Helmet>
      <Container>
        <Typography
          variant="h1"
          textAlign="center"
          mb={4}
          sx={{ fontSize: 24 }}
        >
          Магазин
        </Typography>
        <List
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          {products.map((item) => (
            <Product key={item.id} {...item} />
          ))}
        </List>
      </Container>
    </section>
  );
};

export default ShopPage;
