import { FC, FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { Masonry } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import arrowSvg from "../../assets/gallery-page/arrow.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { url } from "../../utils/constants";
import {
  deleteGalleryImage,
  deleteGalleryVideo,
  postGalleryImage,
  postGalleryVideo,
} from "../../services/slices/gallery-slice";
import StyledLoader from "../../components/styled-loader/styled-loader";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";

const ImageItem: FC<{
  id: number;
  onClick: () => void;
}> = ({ id, onClick }) => {
  return (
    <Box
      component="img"
      src={`${url}/photos/${id}`}
      alt="Картинка"
      onClick={onClick}
    />
  );
};

const VideoItem: FC<{
  id: number;
  onClick: () => void;
}> = ({ id, onClick }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PlayCircleIcon sx={{ width: 30, height: 30 }} />
      </Box>
      <Box
        sx={{ maxWidth: "100%" }}
        key={id}
        component="video"
        src={`${url}/videos/${id}`}
        onClick={onClick}
      />
    </Box>
  );
};

const StyledButton = (props: { onClick: () => void; isLeft?: boolean }) => {
  const theme = useTheme();

  return (
    <Button
      sx={{
        minWidth: "auto",
        marginLeft: props.isLeft ? { xs: 2, md: 10 } : undefined,
        marginRight: props.isLeft ? undefined : { xs: 2, md: 10 },
        height: { xs: 30, md: 60 },
        width: { xs: 30, md: 60 },
        padding: { xs: 2, md: 4 },
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.dark,
        boxSizing: "border-box",
      }}
      variant="contained"
      onClick={props.onClick}
    >
      <Box
        sx={{
          width: { xs: 20, lg: 30 },
          transform: props.isLeft ? "rotate(180deg)" : undefined,
        }}
        component="img"
        src={arrowSvg}
        alt="Кнопка"
      />
    </Button>
  );
};

const GalleryPage: FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fileIndex, setFileIndex] = useState(0);
  const photoInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const {
    files,
    getImagesRequest,
    getVideosRequest,
    postImageRequest,
    postVideoRequest,
  } = useAppSelector((store) => store.gallery);
  const dispatch = useAppDispatch();

  const handleModalOpen = (index: number) => {
    setFileIndex(index);
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleOverlayClick = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    if (evt.target === evt.currentTarget) {
      setIsOpen(false);
    }
  };

  const handleButtonPrev = () => {
    if (fileIndex - 1 < 0) return setFileIndex(files.length - 1);

    setFileIndex(fileIndex - 1);
  };

  const handleButtonNext = () => {
    if (fileIndex + 1 >= files.length) return setFileIndex(0);

    setFileIndex(fileIndex + 1);
  };

  const handleFormSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    if (photoInputRef.current?.files) {
      const files = photoInputRef.current.files;

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.set("file", files[i]);
        dispatch(postGalleryImage(formData));
      }
    }

    if (videoInputRef.current?.files) {
      const files = videoInputRef.current.files;

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.set("file", files[i]);
        dispatch(postGalleryVideo(formData));
      }
    }

    if (photoInputRef.current?.value) {
      photoInputRef.current.value = "";
    }

    if (videoInputRef.current?.value) {
      videoInputRef.current.value = "";
    }
  };

  const handleFileDelete = () => {
    const file = files[fileIndex];

    if (file.type === "image") {
      dispatch(deleteGalleryImage(file.id));
    }

    if (file.type === "video") {
      dispatch(deleteGalleryVideo(file.id));
    }
  };

  useEffect(() => {
    if (!files.length) {
      setIsOpen(false);
    }
  }, [files.length]);

  if (getImagesRequest || getVideosRequest) {
    return (
      <Container sx={{ minHeight: "100vh" }}>
        <Typography variant="h4" textAlign="center" mb={4}>
          Галерея
        </Typography>
        <StyledLoader />
      </Container>
    );
  }

  return (
    <Container sx={{ minHeight: "100vh" }}>
      <Helmet>
        <title>Галерея</title>
        <meta name="description" content="Фотографии с соревнований" />
        <meta
          name="keywords"
          content=""
        />
      </Helmet>
      <Loader open={postImageRequest || postVideoRequest} />
      <Typography variant="h1" textAlign="center" mb={4} fontSize={24}>
        Галерея
      </Typography>
      {isAdmin && (
        <Box
          component="form"
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "center",
          }}
          onSubmit={handleFormSubmit}
        >
          <Box
            sx={{
              maxWidth: 600,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
            }}
          >
            <input
              ref={photoInputRef}
              type="file"
              accept="image/jpeg, image/jpg"
              multiple
              hidden
            />
            <input
              ref={videoInputRef}
              type="file"
              accept="video/mp4, video/quicktime, video/avi"
              multiple
              hidden
            />
            <Button
              color="tertiary"
              variant="contained"
              onClick={() =>
                photoInputRef.current && photoInputRef.current.click()
              }
              fullWidth
            >
              Добавить фотографии
            </Button>
            <Button
              color="tertiary"
              variant="contained"
              onClick={() =>
                videoInputRef.current && videoInputRef.current.click()
              }
              fullWidth
            >
              Добавить видео
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      )}
      <Masonry columns={{ xs: 1, md: 2, lg: 3, xl: 4 }} spacing={2}>
        {files.map(({ id, type }, index) => {
          if (type === "video") {
            return (
              <VideoItem
                key={id}
                id={id}
                onClick={() => handleModalOpen(index)}
              />
            );
          }

          if (type === "image") {
            return (
              <ImageItem
                key={id}
                id={id}
                onClick={() => handleModalOpen(index)}
              />
            );
          }
        })}
      </Masonry>
      <Modal
        sx={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
        open={isOpen}
        onClose={handleModalClose}
      >
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={handleOverlayClick}
        >
          <StyledButton isLeft={true} onClick={handleButtonPrev} />
          <Box sx={{ maxWidth: "70%", position: "relative" }}>
            <IconButton
              sx={{ position: "absolute", top: -40, right: -40 }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
            {isAdmin && (
              <IconButton
                sx={{ position: "absolute", bottom: -40, right: -40 }}
                onClick={handleFileDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
            {files[fileIndex] && files[fileIndex]?.type === "image" ? (
              <Box
                sx={{ maxWidth: "100%", maxHeight: "80vh" }}
                component="img"
                src={`${url}/photos/${files[fileIndex]?.id}`}
                alt="Картинка"
              />
            ) : files[fileIndex]?.type === "video" ? (
              <Box
                sx={{ maxWidth: "100%", maxHeight: "80vh" }}
                component="video"
                src={`${url}/videos/${files[fileIndex]?.id}`}
                controls
              />
            ) : null}
          </Box>
          <StyledButton onClick={handleButtonNext} />
        </Box>
      </Modal>
    </Container>
  );
};

export default GalleryPage;
